export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
    key: 'material_number',
  },
  {
    title: '产品名称',
    dataIndex: 'material_name',
    key: 'material_name',
  },
  {
    title: '英文名称',
    dataIndex: 'material_english_name',
    key: 'material_english_name',
  },
  {
    title: '批次编号',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '库存数量',
    dataIndex: 'remain_quantity',
    key: 'remain_quantity',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]